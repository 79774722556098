<template>
  <div>
    <MyTable
      :rows="rows"
      :columns="$t('refund_requests_table')"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'reference_id'">
          <b-button
            variant="primary"
            class="w-100"
            :to="`/delivery-summary/${props.row.id}`"
          >
            {{ props.row.reference_id }}
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'details'">
          <span v-if="props.row.refund.type.value === 1">
            <span class="d-block">
              Bank Name: {{ props.row.refund.bank.name }}
            </span>
            <span class="d-block">
              Account #: {{ props.row.refund.bank_account_number }}
            </span>
          </span>
          <span v-else>
            <span class="d-block">
              Refund Via Store Credit
            </span>
            <span class="d-block">
              Amount: {{ props.row.refund.store_credit }}
            </span>
          </span>
        </span>

        <span v-else-if="props.column.field === 'refund.amount'">
          {{ $helpers.formatTotal(props.row.refund.amount) }}
        </span>

        <span v-else-if="props.column.field === 'date_added'">
          {{ $helpers.formatDate(props.row.date_added) }}
        </span>

        <span v-else-if="props.column.field === 'status'">
          <b-badge
            :variant="props.row.refund.status.color_name"
            class="w-100 p-1 font-weight-bolder"
          >{{ props.row.refund.status.description }}</b-badge>
        </span>

        <span v-else-if="props.column.field === 'type'">
          <b-badge
            :variant="props.row.refund.type.color_name"
            class="w-100 p-1 font-weight-bolder"
          >{{ props.row.refund.type.text }}</b-badge>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>
  </div>
</template>

<script>
import axios from '@axios'
import MyTable from '@/components/MyTable.vue'

export default {
  name: 'RefundRequests',
  components: {
    MyTable,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
    }
  },
  created() {
    this.fetchRefundOrders()
  },
  methods: {
    async fetchRefundOrders() {
      this.isLoading = true
      const response = await axios.get('refund-orders')
      if (!response.status === 200) {
        this.$swal({
          title: 'Error!',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn-primary',
          },
        })
        this.isLoading = false
        return
      }

      this.rows = response.data
      this.isLoading = false
    },
  },
}
</script>

<style>

</style>
